<template>
  <div class="form-element">
    <div class="sign-in-up" id="sign-up">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <h2 style="text-align: center">
            Sign up to pre-fill your mortgage application
          </h2>

          <div class="social-icons">
            <ul>
              <!-- Google Login -->
              <li>
                <button type="button" @click="googleSignIn" class="active">
                  <img src="../assets/images/google-ico.svg" alt="google icon" />
                  <span>Continue with Google</span>
                </button>
              </li>
              <!-- Facebook Login -->
              <li>
                <v-facebook-login
                  class="facebook-login"
                  app-id="497045238736125"
                  v-model="fbModel"
                  @sdk-init="facebookSdkInit"
                ></v-facebook-login>
              </li>
              <!-- LinkedIn Login -->
              <li>
                <button type="button" @click="linkedInPopup">
                  <img src="../assets/images/linkedin-ico.svg" alt="linkedin icon" />
                </button>
              </li>
            </ul>
          </div>
          <div class="divider">
            <span>Or</span>
          </div>
          <form @submit.prevent="signUp" @keyup.enter="signUp">
            <div class="form-group">
              <label class="form-label">First Name</label>
              <input
                v-model.trim="$v.firstName.$model"
                type="text"
                class="form-control"
                :class="$v.firstName.$error ? 'is-invalid' : ''"
              />
              <small class="invalid-feedback" v-if="!$v.firstName.required"
                >First Name field is required.</small
              >
            </div>
            <div class="form-group">
              <label class="form-label">Last Name</label>
              <input
                v-model.trim="$v.lastName.$model"
                type="text"
                class="form-control"
                :class="$v.lastName.$error ? 'is-invalid' : ''"
              />
              <small class="invalid-feedback" v-if="!$v.lastName.required"
                >Last Name field is required.</small
              >
            </div>
            <div class="form-group">
              <label class="form-label">Email</label>
              <input
                v-model.trim="$v.email.$model"
                type="email"
                inputmode="email"
                class="form-control"
                :class="$v.email.$error ? 'is-invalid' : ''"
              />
              <small class="invalid-feedback" v-if="!$v.email.required"
                >Email field is required.</small
              >
              <small class="invalid-feedback" v-else-if="!$v.email.email"
                >Please enter a valid email address.</small
              >
            </div>
            <div class="form-group">
              <label class="form-label">Password</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.password.$model"
                  :type="showPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.password.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showPass = !showPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i :class="showPass ? 'far fa-eye' : 'fas fa-eye-slash'"></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.password.$error">
                <small v-if="!$v.password.required" class="invalid-feedback d-block"
                  >Password field is required.</small
                >
                <small v-else-if="!$v.password.maxLength" class="d-block invalid-feedback"
                  >Maximum {{ $v.password.$params.maxLength.max }} characters are
                  allowed.</small
                >
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Confirm Password</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.confirmPassword.$model"
                  :type="showCoPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.confirmPassword.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showCoPass = !showCoPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i :class="showCoPass ? 'far fa-eye' : 'fas fa-eye-slash'"></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.confirmPassword.$error">
                <small
                  v-if="!$v.confirmPassword.required"
                  class="d-block invalid-feedback"
                  >Confirm Password field is required.</small
                >
                <small
                  v-else-if="!$v.confirmPassword.sameAs"
                  class="d-block invalid-feedback"
                  >Password and Confirm Password fields does not match.</small
                >
              </div>
            </div>
            <div class="button w-100">
              <button
                type="button"
                class="btn w-100"
                :class="!$v.$invalid ? 'btn-red' : ''"
                :disabled="$v.$invalid"
                @click="signUp"
              >
                <span>Continue</span>
              </button>
            </div>
            <div class="account-text">
              <p>
                Already have an account?
                <button type="button" class="custom-underline-text" @click="showSignIn()">
                  <strong>Sign In</strong>
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, email, maxLength, sameAs } from "vuelidate/lib/validators";
import VFacebookLogin from "vue-facebook-login-component";
import commonFunctions from "./../mixins/commonfunctions";

export default {
  name: "Internal-Sign-In",
  mixins: [commonFunctions],
  components: {
    VFacebookLogin,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      showPass: false,
      showCoPass: false,
      fbData: {},
      fbScope: {},
      fbModel: { connected: false },
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      maxLength: maxLength(25),
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler(newVal) {
        if (newVal.query.code) await this.linkedInSignIn();
      },
    },

    fbModel(newVal, oldVal) {
      if (newVal.connected === true && oldVal.connected === undefined) {
        this.fbData.getLoginStatus(async (response) => {
          if (response.status === "connected") {
            await this.fbData.logout(() => {});
            await this.fbScope.logout();
          }
        });
      }

      this.fetchFacebookModel(newVal);

      if (newVal.connected === true && oldVal.connected === false) this.facebookSignin();
    },
  },
  mounted() {
    if (this.getAppFlow === "" && !Object.keys(this.$route.query).length)
      this.fetchAppFlow("Buying");
    if (!this.getQuestions.length && !Object.keys(this.$route.query).length)
      this.getQuestionnaire();

    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters([
      "getAppFlow",
      "getUserId",
      "getOrgId",
      "getProspectId",
      "getAssets",
      "getQuestions",
      "getActiveQuestion",
      'getCoPilotSession'
    ]),
  },
  methods: {
    ...mapActions([
      "fetchProspectEmail",
      "fetchAppFlow",
      "fetchApplications",
      "fetchIsAuthenticated",
      "fetchShowInternalAuth",
      "fetchSections",
      "fetchQuestions",
      "fetchSubQuestions",
      "fetchActiveQuestion",
      "fetchActiveSection",
      "fetchLoaderStatus",
      "fetchCompLoaderStatus",
      "fetchSocialLogin",
      "fetchFacebookData",
      "fetchFacebookScope",
      "fetchFacebookModel",
    ]),

    showSignIn() {
      this.$emit("show-signin", true);
    },

    async getQuestionnaire() {
      this.fetchCompLoaderStatus(true);
      let user_id = this.getUserId || null;

      await this.$http
        .get(
          "/question/GetProspectQuestions/" +
            this.getAppFlow +
            "/" +
            user_id +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchSections(resp.sidebarSections);
            this.fetchQuestions(resp.appQuestions);
            this.fetchSubQuestions(resp.subQuestions);
            this.fetchActiveQuestion(1);

            let getSectionName = this.getQuestions.find(
              (v) => v.questionIndex === this.getActiveQuestion
            );

            this.fetchActiveSection(getSectionName.sectionName);
          }

          if (resp.response === "Error") this.err(resp.message);
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },

    async signUp() {
      this.fetchCompLoaderStatus(true);

      let payLoad = {
        prospectFirstName: this.capitalizeFirstChar(this.firstName),
        prospectLastName: this.capitalizeFirstChar(this.lastName),
        prospectEmail: this.email,
        password: this.password,
        orgId: this.getOrgId,
        userId: this.getProspectId,
        flowName: this.getAppFlow,
      };

      await this.$http
        .post("/account/prospect/register", payLoad)
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(
              "Registration successful. Please wait while we sign you in automatically."
            );

            await this.appLogin();
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchCompLoaderStatus(false);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
          this.fetchCompLoaderStatus(false);
        });
    },

    async appLogin() {
      let payLoad = {
        authenticated: false,
        flowName: this.getAppFlow,
        prospectEmail: this.email,
        prospectPassword: this.password,
        prospectFirstName: "", //will be always empty string while manual login
        prospectLastName: "", //will be always empty string while manual login
        orgId: this.getOrgId,
        userId: this.getProspectId,
        isCopilotSession: this.getCoPilotSession
      };

      await this.$http
        .post("/account/Prospect/Auth", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchIsAuthenticated(true);
            this.fetchProspectEmail(payLoad.prospectEmail);
            this.fetchApplications(resp.applications);

            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.password = "";
            this.confirmPassword = "";
            this.$v.$reset();

            this.$router.push("/switch-application");
            this.fetchShowInternalAuth(false);
          }

          if (resp.response === "Error") this.err(resp.message);
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },

    async socialLogin(payload, platform) {
      if (platform === "LinkedIn") this.fetchCompLoaderStatus(true);

      await this.$http
        .post("/account/Prospect/Auth", payload)
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success("Login Successful");
            this.fetchIsAuthenticated(true);
            this.fetchProspectEmail(payload.prospectEmail);
            this.fetchApplications(resp.applications);
            this.fetchSocialLogin(platform);

            this.$router.push("/switch-application");
            this.fetchShowInternalAuth(false);
          }

          if (resp.response === "Error") this.err(resp.message);
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },

    async googleSignIn() {
      this.fetchCompLoaderStatus(true);

      let googleUser = await this.$gAuth.signIn();
      let authorization = this.$gAuth.isAuthorized;
      let userName = googleUser.getBasicProfile().getName().split(" ");

      let payload = {
        authenticated: authorization,
        flowName: this.getAppFlow,
        prospectEmail: googleUser.getBasicProfile().getEmail(),
        prospectFirstName: this.capitalizeFirstChar(userName[0]),
        prospectLastName: this.capitalizeFirstChar(userName[1]),
        orgId: this.getOrgId,
        userId: this.getProspectId,
        prospectPassword: this.generatePass(17), //will always generate random string while social login
      };

      await this.socialLogin(payload, "Google");
    },

    async linkedInSignIn() {
      this.fetchCompLoaderStatus(true);

      let code = this.$route.query.code;

      if (code) {
        let url =
          "https://digitalmortgageapi.candidcrm.com/socialLogin/linkedin/getAccessTokenWithUserData?code=" +
          code;

        let userEmail;
        let firstName;
        let lastName;

        // use the new axios instance to make your get request
        await this.$http
          .get(url)
          .then(async (response) => {
            firstName = response.data.localizedFirstName;
            lastName = response.data.localizedLastName;
            userEmail = response.data.emailAddress;

            let payload = {
              authenticated: true,
              flowName: this.getAppFlow,
              prospectEmail: userEmail,
              prospectFirstName: this.capitalizeFirstChar(firstName),
              prospectLastName: this.capitalizeFirstChar(lastName),
              orgId: this.getOrgId,
              userId: this.getProspectId,
              prospectPassword: this.generatePass(17), //will always generate random string while social login
            };

            await this.socialLogin(payload, "LinkedIn");
          })
          .catch((errors) => {
            console.log(errors);
            this.fetchCompLoaderStatus(false);
          });
      }
    },

    linkedInPopup() {
      let link =
        "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&client_id=77urg3qa0jbwtg&redirect_uri=https://digitalmortgage.candidcrm.com/sign-in/&scope=r_liteprofile,r_emailaddress";

      window.location = link;
    },

    facebookSdkInit({ FB, scope }) {
      this.fetchFacebookData(FB);
      this.fetchFacebookScope(scope);

      this.fbData = FB;
      this.fbScope = scope;
    },

    facebookSignin() {
      this.fetchCompLoaderStatus(true);

      let userEmail;
      let userName;

      this.fbData.api("/me", "GET", { fields: "name,email" }, (user) => {
        const response = user;
        userEmail = response.email;
        userName = response.name;

        let payload = {
          authenticated: this.fbModel.connected,
          flowName: this.getAppFlow,
          prospectEmail: userEmail,
          prospectFirstName: userName
            ? this.capitalizeFirstChar(userName.split(" ")[0])
            : "",
          prospectLastName: userName
            ? this.capitalizeFirstChar(userName.split(" ")[1])
            : "",
          orgId: this.getOrgId,
          userId: this.getProspectId,
          prospectPassword: this.generatePass(17), //will always generate random string while social login
        };

        this.socialLogin(payload, "Facebook");
      });
    },

    generatePass(n) {
      let password = "";
      let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

      for (let i = 1; i <= n; i++) {
        let char = Math.floor(Math.random() * str.length);
        password += str.charAt(char);
      }

      return password;
    },
  },
};
</script>

<style scoped>
.sign-in-up .form {
  margin: 45px 0 !important;
}

.sign-in-up .form .form__content {
  padding: 0;
  max-width: 400px;
  margin: 0;
}

.forgot-password {
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-color);
  width: 100%;
  text-align: right;
}

.sign-in-up .form .form__content .social-icons ul {
  justify-content: center;
}

.sign-in-up .form .form__content {
  border: none;
  box-shadow: none;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

form .flex-nowrap {
  margin-bottom: 10px;
}

form .flex-nowrap .form-label {
  margin-bottom: 0;
}

.custom-underline-text {
  text-decoration: underline;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sign-in-up .form .form__content .social-icons ul li:not(:last-child) {
    padding-right: 5px;
  }
}
</style>

<style>
.sign-in-up .form .form__content .social-icons ul li .facebook-login {
  cursor: pointer;
  max-width: 45px;
  min-width: unset;
  font-size: 0;
  padding: 0;
  position: relative;
  background: transparent url(../assets/images/facebook-ico.svg) no-repeat;
  background-position: center;
}

.sign-in-up .form .form__content .social-icons ul li .facebook-login svg {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  color: #4267b2;
  position: absolute;
  display: none;
}

.sign-in-up .form .form__content .social-icons ul li .facebook-login .loader {
  width: 20px;
  opacity: 1;
  visibility: visible;
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}
</style>
